import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {colors, fontfamily} from '../lib/variables'
import {Link} from 'gatsby'

const StyledBasePortableText = styled.div`
  padding: 2rem 0;
  max-width: 640px;
  margin: 0 auto;
  h1,
  h2,
  h3,
  h4{
    text-align: left;
    font-family: ${fontfamily.jaRounded};
    margin-bottom: 2.4rem;
    padding-top: 3.6rem;
    color: ${colors.navy};
    position: relative;
    padding-bottom: 0.8rem;
    font-weight: 700;
    & :after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background: ${colors.yellow};
      height: 2px;
      width: 100%;
      border-radius: 4px;
    }
  }

  h2{
    font-size: 2.0rem;
  }
  h3{
    font-size: 1.8rem;
    padding-top: 2.4rem;
    margin-bottom: 1.6rem;
    padding-bottom: 0rem;
    & :after{
      display: none;
    }
  }
  h4{
    font-size: 1.6rem;
    padding-top: 1.8rem;
    margin-bottom: 1.4rem;
    padding-bottom: 0rem;
    & :after{
      display: none;
    }
  }

  ul,
  ol{
    font-size: 1.6rem;
    list-style-position: outside;
    margin: 0 0 0 2.6rem;
    padding: 0;
    line-height: 1.75;
  }
  li{
    margin: 0 0 2.4rem 0;
  }
  strong,
  bold{
    color: ${colors.navy};
  }
 `

const StyledLink = styled(props => <Link {...props} />)`
  color: ${colors.blue};
  font-weight: 400;
  font-size: 1.8rem;
`

const ExternalLink = styled.a`
  color: ${colors.blue};
  font-weight: 400;
  font-size: 1.6rem;
`

const Small = styled.small`
  font-size: 1.2rem;
`

const Heading = props => {
  const {title, color, stripe} = props
  return (
    <>
      <StyledBasePortableText>
        <h2>登録内容をご確認ください</h2>
        <p>お申込いただきありがとうございます。</p>
        <p>お申込みフォームの入力と決済が完了した方には、合計で2通のメール（フォーム確認メールおよび領収書）が送信されます。<br />届かない場合、メールアドレスの打ち間違いや、決済未完了の可能性ございますので <ExternalLink href='mailto:support@nyikuei.org'>support@nyikuei.org</ExternalLink>までご連絡お願いします。</p>
        <p>
          尚、フォームの確認メールは入力していただいた保護者用アドレスに届き、領収書は決済時に入力していただいたアドレスに送信されます。ご注意ください。
        </p>
        <h2>アンケートのお願い</h2>
        <p>今後のウェブ決済実施のためアンケートのご協力をお願いしています。<br />数分で完了する匿名アンケートとなっておりますのでご協力ください</p>
        <iframe className='airtable-embed' src='https://airtable.com/embed/shro3IZk4lA95bMzO?backgroundColor=yellow' frameBorder='0' onmousewheel='' width='100%' height='1200' />

      </StyledBasePortableText>
    </>
  )
}
export default Heading
